<template>
  <layout :loading="loading">
    <template #header>
      <h1 class="title-1">Console Commands</h1>
    </template>
    <div>
      <b-card-group deck>
        <b-card
            class="mb-4"
            title="check:attendance"
        >
          <b-card-text>Check existence of yesterday's attendance from employees and, in case of absence, affixing a penalty</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('check:attendance')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="check:birthday"
        >
          <b-card-text>Send Slack notification to HR manager about employee birthdays that occur in the next 3 days
          </b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('check:birthday')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="make:annual-calendar"
        >
          <b-card-text>Create calendar for next year</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('make:annual-calendar')">Execute</b-button>
        </b-card>
      </b-card-group>
    </div>
    <div>
      <b-card-group deck>
        <b-card
            class="mb-4"
            title="check:contract"
        >
          <b-card-text>Send Slack notification to HR manager if there are expiring contracts that occur in the next 3 days
          </b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('check:contract')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="currency-rate:import"
        >
          <b-card-text>Import currency rates</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('currency-rate:import')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="check:dismissal-warning"
        >
          <b-card-text>Send Slack notification to employee if Dismissal Warning decision date approaches</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('check:dismissal-warning')">Execute
          </b-button>
        </b-card>
      </b-card-group>
    </div>
    <div>
      <b-card-group deck>
        <b-card
            class="mb-4"
            title="update:employee-status"
        >
          <b-card-text>Update employee`s status</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('update:employee-status')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="send:data-to-analytics"
        >
          <b-card-text>ERP Analytics posting</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('send:data-to-analytics')">Execute</b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="notify:lunch-list"
        >
          <b-card-text>Notify in Slack office manager with the list of people having lunch in the office cafeteria
          </b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('notify:lunch-list')">Execute
          </b-button>
        </b-card>
      </b-card-group>
    </div>
    <div>
      <b-card-group deck>
        <b-card
            class="mb-4"
            title="notify:on-time-check-in-warning --location_id=1"
        >
          <b-card-text>Send Slack notification to employee if one has not checked in (office)</b-card-text>
          <b-button href="#" variant="primary"
                    @click="sendConsoleCommand('notify:on-time-check-in-warning --location_id=1')">Execute
          </b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="notify:on-time-check-in-warning --location_id=4"
        >
          <b-card-text>Send Slack notification to employee if one has not checked in (remote)</b-card-text>
          <b-button href="#" variant="primary"
                    @click="sendConsoleCommand('notify:on-time-check-in-warning --location_id=4')">Execute
          </b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="notify:feedback"
        >
          <b-card-text>Send Slack notification to employees to provide their feedback</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('notify:feedback')">Execute</b-button>
        </b-card>
      </b-card-group>
    </div>
    <div>
      <b-card-group deck>
        <b-card
            class="mb-4"
            title="send:attendances-to-openai"
        >
          <b-card-text>Send timesheet to OpenAI</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('send:attendances-to-openai')">Execute
          </b-button>
        </b-card>
        <b-card
            class="mb-4"
            title="soa:sync"
        >
          <b-card-text>SOA database tables synchronization</b-card-text>
          <b-button href="#" variant="primary" @click="sendConsoleCommand('soa:sync')">Execute</b-button>
        </b-card>
      </b-card-group>
    </div>
    <b-modal id="bv-modal-example" hide-footer :modalClass="'modal-confirm'" :centered="true">
      <div class="d-block" style="padding-bottom: 20px">
        <h3>Please confirm</h3>
        <p>Are you sure you want to execute {{ this.command }}?</p>
      </div>
      <div class="d-flex justify-content-end">
        <b-button class="btn btn-success mr-2" @click.prevent="$bvModal.hide('bv-modal-example')">Cancel</b-button>
        <b-button class="btn btn-danger" @click.prevent="acceptCommand">Confirm</b-button>
      </div>
    </b-modal>
  </layout>
</template>
<script>
export default {
  name: 'Commands',
  data() {
    return {
      loading: false,
      command: null
    }
  },

  methods: {
    async sendConsoleCommand(command) {
      this.command = command
      this.$bvModal.show('bv-modal-example')
    },
    async acceptCommand(){
      this.$bvModal.hide('bv-modal-example')
      this.loading = true
      await this.$store.dispatch('settings/sendConsoleCommand', {'command': this.command})
      this.loading = false
    }
  },
}
</script>